@font-face {
	font-family: 'Maison Neue';
	src: url('../assets/fonts/maison-neue/maison-neue-light.otf') format('opentype');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../assets/fonts/maison-neue/maison-neue-light-italic.otf') format('opentype');
	font-style: italic;
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../assets/fonts/maison-neue/maison-neue-book.otf') format('opentype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../assets/fonts/maison-neue/maison-neue-bold.otf') format('opentype');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('../assets/fonts/maison-neue/maison-neue-bold-italic.otf') format('opentype');
	font-style: italic;
	font-weight: 700;
	font-display: swap;
}